import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://03d05bb451924521a0b789ca21fa8f88@glitchtip.vaas.me/1",
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

function setUserInSentry() {
  var email = document.querySelector('meta[name="email"]')?.content;
  var username = document.querySelector('meta[name="username"]')?.content;
  if (email) {
    Sentry.setUser({ email: email });
  }
  if (username) {
    Sentry.setUser({ username: username });
  }
}

document.addEventListener('turbo:load', setUserInSentry);
setUserInSentry();
